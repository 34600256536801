<template>
  <section id="logo-container">
    <div class="container">
      <div class="absolute top-0 h-12 w-full my-8 z-10 flex-container">
        <!-- Logo linking to the home page -->
        <router-link to="/" class="logo">
          <img src="../assets/emale-logo.svg" alt="MTU Emale logo" />
        </router-link>

        <!-- External link to the campaign page -->
        <div class="campaign-link">
          <a href="https://www.minakasynnitajana.ee/" target="_blank" rel="noopener">
            #minakasünnitajana
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
/* Container styling */
.container {
  width: 90%;
  max-width: 1088px;
  margin: auto;
  height: 100%;
  position: relative;
}

/* Flexbox to align logo and link */
.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

/* Logo styling */
.logo {
  display: inline-block;
}

/* Campaign link styling */
.campaign-link {
  display: inline-block;
  font-size: 1.2rem;
  z-index: 1;
}

.campaign-link a {
  text-decoration: dashed underline;
  color: #f9a827;
  z-index: 100; /* Ensures the link is on top */
}

/* Responsive layout for smaller screens */
@media (max-width: 768px) {

  .campaign-link {
    margin-top: 10px;
  }
}
</style>
