<template>
  <section id="solution-section" class="mt-20 font-sans font-light text-brown">
    <div class="mb-16">
      <h1 class="font-serif text-center text-xl">Emadele hääle andmine.</h1>
      <img class="mx-auto" src="../assets/heading-line.svg" alt="Line under heading" />
    </div>
    <div class="container">
      <div class="relative">
        <div class="mb-16 sm:mb-8">
          <p class="tracking-wide text-center leading-8 sm:mx-auto sm:max-w-lg sm:text-lg">
            Selleks oleme asutanud MTÜ Emale.
            <br />Soovime anda hääle emade rääkimata lugudele, soovidele, muredele ja vajadustele. Läbi selle loome muutusi, ühtehoidmist ja paremat elukvaliteeti tulevastele ning praegustele emadele ja lastele.
          </p>
        </div>
        <div>
          <img
            class="mx-auto w-56 sm:mx-0 lg:absolute lg:bottom-0"
            src="../assets/solution-image.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  width: 90%;
  max-width: 1088px;
  margin: auto;
  height: 100%;
}
</style>