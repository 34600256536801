<template>
  <section id="concern-section" class="mt-20 font-sans font-light text-brown">
    <div class="mb-16">
      <h1 class="font-serif text-center text-xl">Meie siht.</h1>
      <img class="mx-auto" src="../assets/heading-line.svg" alt="Line under heading" />
    </div>
    <div class="container">
      <div class="lg:flex">
        <!-- Concern content left -->
        <div
          class="mb-8 w-full bg-yellow-light p-8 rounded-lg sm:max-w-lg sm:mx-auto lg:w-1/2 lg:text-xl lg:w-1/2 lg:mr-2"
        >
          <p
            class="mb-8 tracking-wide leading-6 sm:text-center lg:text-left lg:text-left"
          >Eestis on palju kitsaskohti, mis vajavad praktilis lahendusi, et toetada naisi ja emasid.</p>
          <p
            class="mb-8 tracking-wide leading-6 sm:text-center lg:text-left"
          >Naiste mitterahalise panuse hindamine, lähisuhtevägivald, varalised suhted kooselus ning rasedate ja sünnitajate õigused on vaid mõned näited..</p>
          <p
            class="tracking-wide leading-6 sm:text-center lg:text-left"
          >Tuleb leida kitsaskohad seadustes, hariduses ja ühsikonnas, et praegused ja tulevased emad oleks kaitstud.</p>
        </div>
        <!-- Concern content right -->
        <div
          class="mb-8 w-full bg-yellow-light p-8 rounded-lg sm:max-w-lg sm:mx-auto lg:w-1/2 lg:text-xl lg:ml-2"
        >
          <p
            class="mb-8 tracking-wide leading-6 sm:text-center lg:text-left"
          >Oluline on võimaldada emadele parim ettevalmistus, oma õiguste tundmine ning juriidiline, emotsionaalne ja majanduslik turvatunne.</p>
          <p
            class="mb-8 tracking-wide leading-6 sm:text-center lg:text-left"
          >Palju on veel teha, et näidata emadele, kuidas meie panust märgatakse ja peetakse oluliseks.</p>
          <p
            class="mb-8 tracking-wide leading-6 sm:text-center lg:text-left"
          >Ainult sõnadest ei piisa.</p>
          <p class="mb-8 tracking-wide leading-6 sm:text-center lg:text-left">Vaja on tegusid.</p>
        </div>
      </div>
      <!-- Concern section image -->
      <div class="mb-8 flex justify-end">
        <img src="../assets/header-background-leaves.svg" alt="Concern section image" />
      </div>
      <!-- Scroll-down indicator -->
      <div class>
        <img class="mx-auto" src="../assets/arrow_down.svg" alt="Scroll down indicator" />
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  width: 90%;
  max-width: 1088px;
  margin: auto;
  height: 100%;
}
</style>
